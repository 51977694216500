<template>
	<div>
		<vs-prompt
			class="edit-moderation"
            title="Edit Question"
            @accept="submitData()"
            :is-valid="isFormValid"
            :accept-text="$t('Save')"
            :disabled-outside="true"
            :active.sync="isModalActiveLocal">
            <!-- reply section -->
            <div class="ml-2 relative">
              <div class="flex">
                <vs-textarea
                    class="w-full input-edit-moderation"
                    name="item-content"
                    rows="4"
                    v-model="dataModeration"
                    @input="validateCharCount"
                    id="chatInputQuestion"
                />
                <picker
                  set="emojione"
                  title="Pick your emoji…"
                  :style="{ position: 'absolute', bottom: '50px', right: '20px' }"
                  :showPreview="false"
                  :sheetSize="32"
                  :disabled="isSending"
                  @select="onEmojiSelect"
                  v-if="isShowEmoji"/>
                  <div class="emoji-input pointer mt-2">
                    <vs-icon icon="sentiment_satisfied_alt" @click="showEmoji()"></vs-icon>
                  </div>
                </div>
                <div class="text-right">{{ characterCount }}</div>
                <!-- dropdown user on admin side -->
                <div class="relative items-center" style="z-index: 9999;">
                    <vs-dropdown class="absolute mt-8 pt-1 dd-actions cursor-pointer" vs-trigger-click>
                        <div>
                            <div class="flex px-2 items-center">
                                <svg v-if="dataCurrentRole === 'Moderator'" class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                                <svg v-else class="w-6 mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>
                                <div class="ml-2">{{ dataCurrentRole }}</div>
                                <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path fill="gray" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                            </div>
                        </div>
                        <vs-dropdown-menu>
                            <!-- Moderator -->
                            <vs-dropdown-item>
                                <div @click="dataCurrentRole = 'Moderator'" class="flex items-center moderator-menu">
                                    <div>
                                        <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                                    </div>
                                    <div class="ml-2">{{ $t('Moderator') }}</div>
                                </div>
                            </vs-dropdown-item>
                            <!-- Possibly -->
                            <vs-dropdown-item>
                                <div @click="dataCurrentRole = 'Anonymous'" class="flex items-center moderator-menu">
                                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                                    <div class="ml-2">{{ $t('Anonymous') }}</div>
                                </div>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="handleClickCustom">
                                <div  class="flex items-center moderator-menu">
                                    <!-- <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div> -->
                                    <span class="flex" v-if="!isCustomRole">
                                      <div v-if="!dataCustomCurrentRole" class="ml-2">+</div>
                                      <div v-if="!dataCustomCurrentRole" style="margin-left: 12px" class="italic">{{ $t('Add new') }}</div>
                                      <div class="flex items-center w-full justify-between" v-if="dataCustomCurrentRole">
                                        <div class="flex items-center w-full">
                                          <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                                          <div class="ml-2 truncate" style="max-width: 120px;">{{ dataCustomCurrentRole }}</div>
                                        </div>
                                        <div @click.stop="isCustomRole = true" class="ml-2 absolute right-0 pr-4  ">
                                          <svg class="w-5 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="grey" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                                        </div>
                                      </div>
                                    </span>
                                    <div v-if="isCustomRole">
                                      <div style="" class="italic focus:text-black">
                                        <vs-input
                                          class="w-full pb-2"
                                          name="name"
                                          v-model="dataCustomCurrentRole"
                                          v-validate="'required'"
                                          data-vv-validate-on="blur"
                                          @blur="handleCustomRole"
                                          @keyup.enter="handleCustomRole"
                                        />
                                      </div>
                                    </div>
                                </div>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
            </div>
        </vs-prompt>
    </div>
</template>

<script>
import questionsApi from '@/api/question';
import {
  getAxiosErrorMessage,
} from '@/lib/helper';
import { Picker } from 'emoji-mart-vue';

export default {
  components: {
    Picker,
  },
  props: {
    isShowEdit: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isShowEdit() {
      if (this.isShowEdit) {
        this.isModalActiveLocal = true;
        if (this.selectedItem) {
          this.dataCurrentRole = this.selectedItem && this.selectedItem.sender_name ? this.selectedItem.sender_name : 'Moderator';
          this.dataModeration = this.selectedItem.name;
          this.dataIdModeration = this.selectedItem.id;
        }
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeEditQuestion');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataIdModeration: null,
      dataModeration: '',
      dataIsReplyEmail: false,
      isSending: false,
      isShowEmoji: false,

      dataCurrentRole: 'Moderator',
      isCustomRole: false,
      dataCustomCurrentRole: '',
    };
  },
  computed: {
    isFormValid() {
      return this.dataModeration !== '';
    },
    eventInputMaxLength() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let settingsInputMaxLength = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) settingsInputMaxLength = settings && settings.question_answer && settings.question_answer.input_max_length ? settings.question_answer.input_max_length : false;
      return settingsInputMaxLength;
    },
    exceedsLimit() {
      return this.dataModeration.length > this.eventInputMaxLength; // Change 10 to your desired character count
    },
    characterCount() {
      return `${this.dataModeration.length}/${this.eventInputMaxLength}`;
    },
  },
  methods: {
    handleClickCustom(event) {
      if (!this.dataCustomCurrentRole || this.isCustomRole) {
        event.stopPropagation();
      }
      this.addNewRole();
    },
    addNewRole() {
      if (!this.dataCustomCurrentRole) {
        this.isCustomRole = true;
      } else {
        this.dataCurrentRole = this.dataCustomCurrentRole;
      }
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    handleCustomRole() {
      this.dataCurrentRole = this.dataCustomCurrentRole;
      this.isCustomRole = false;
      if (!this.dataCustomCurrentRole) this.dataCurrentRole = 'Moderator';
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    showEmoji() {
      this.isShowEmoji = !this.isShowEmoji;
    },
    closeEmoji() {
      this.isShowEmoji = false;
    },
    setInputFocus() {
      // eslint-disable-next-line
			$('#chatInputQuestion').focus();
    },
    onEmojiSelect(emoji) {
      this.dataModeration = `${this.dataModeration} ${emoji.native} `;
      this.closeEmoji();
      this.setInputFocus();
    },
    validateCharCount() {
      if (this.dataModeration.length > this.eventInputMaxLength) { // Change 10 to your desired character count
        this.dataModeration = this.dataModeration.substring(0, this.eventInputMaxLength); // Change 10 to your desired character count
      }
    },
    submitData() {
      this.$vs.loading();
      this.isSending = true;
      const params = {
        name: this.dataModeration,
        sender_name: this.dataCurrentRole,
      };
      const callback = (response) => {
        this.$emit('updateQuestion', response.data);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        this.isSending = false;
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSending = false;
      };
      questionsApi.update(this.dataIdModeration ? this.dataIdModeration : null, params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
    const CustomCurrentRole = localStorage.getItem('CustomCurrentRole');
    this.dataCustomCurrentRole = CustomCurrentRole;
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.moderation-menu {
		width: 120px;
	}
	.moderator-menu {
		width: 160px;
	}
	.edit-moderation.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
        @media (min-width: 500px) {
            min-width: 500px!important;
        }
    }
    .input-edit-moderation {
        border: none!important;
    }
    .emoji-input .vs-icon {
      font-size: 1.8em!important;
			position: relative;
			top: 2px;
			line-height: 1;
			cursor: pointer;
			&:hover {
				opacity: .7;
			}
		}
</style>
