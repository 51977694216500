<template>
	<div v-if="!isFetching" class="pt-2">
    <div style="height: 60px; background-color: #F8F8F8; z-index: 9999" class="w-full top-0 flex justify-between items-center " :class="{'fixed mt-20 pt-4 mb-6': windowWidth > 991}">
      <div class="flex">
        <div class="flex items-center" :class="{'fixed': windowWidth > 991}">
          <div class="font-bold text-lg">
            {{ isQuestionModerated ? $t('Moderation is ON') : $t('Moderation is OFF') }}
          </div>
          <div class="text-sm ml-3 font-semibold text-dark-gray">
            {{ isQuestionModerated ? $t('All question need to be reviewed before all user can see it') : $t('All question are immediately visible to all user') }}
          </div>
        </div>
      </div>
      <div class="pt-1 items-center action-moderation">
        <vs-button v-tooltip="$t('Broadcast Message')" @click="openAnnouncement()" class="ml-2 btn-icon px-2 p-0" color="secondary" type="border">
          <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>bullhorn</title><path d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z" /></svg>
        </vs-button>
        <vs-button v-tooltip="$t('Settings')" @click="openSettings()" class="ml-2 btn-icon px-2 p-0" color="secondary" type="border">
          <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>tune-variant</title><path d="M8 13C6.14 13 4.59 14.28 4.14 16H2V18H4.14C4.59 19.72 6.14 21 8 21S11.41 19.72 11.86 18H22V16H11.86C11.41 14.28 9.86 13 8 13M8 19C6.9 19 6 18.1 6 17C6 15.9 6.9 15 8 15S10 15.9 10 17C10 18.1 9.1 19 8 19M19.86 6C19.41 4.28 17.86 3 16 3S12.59 4.28 12.14 6H2V8H12.14C12.59 9.72 14.14 11 16 11S19.41 9.72 19.86 8H22V6H19.86M16 9C14.9 9 14 8.1 14 7C14 5.9 14.9 5 16 5S18 5.9 18 7C18 8.1 17.1 9 16 9Z" /></svg>
        </vs-button>
      </div>
    </div>
    <div style="height: 56px;" v-if="windowWidth > 991"></div>
    <ItemModeration
      :questions="questions"
      :eventData="eventData"
      @updateQuestion="updateQuestion"
      @publishedQuestion="publishedQuestion"
    >
    </ItemModeration>
    <div class="text-center text-primary font-bold text-xl mt-2">
      <vs-button v-if="!isFetching && totalPage !== page" @click="loadMore()" class="ml-2 btn-icon px-6 py-4">
        {{ $t('Load More') }}
      </vs-button>
    </div>
		<ModalAnnouncement :eventId="eventData && eventData.id ? eventData.id : null" :isShowAnnouncement="isShowAnnouncement" @closeAnnouncement="closeAnnouncement"></ModalAnnouncement>
		<ModalSettingModeration :listModerationLength="questions && questions.length > 0 ? questions.length : 0 " :isShowSettings="isShowSettings" @closeSettings="closeSettings" @publishedAll="publishedAll"></ModalSettingModeration>
    </div>
</template>

<script>
import ItemModeration from '@/components/qna/ItemModeration.vue';
import ModalAnnouncement from '@/components/qna/ModalAnnouncement.vue';
import ModalSettingModeration from '@/components/qna/ModalSettingModeration.vue';

import { getAxiosErrorMessage } from '@/lib/helper';
import questionsApi from '@/api/question';

export default {
  components: {
    ItemModeration,
    ModalAnnouncement,
    ModalSettingModeration,
  },
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
    keyword: {
      type: String,
      default: () => '',
    },
    filterData: {
      type: String,
      default: () => '',
    },
  },
  sockets: {
    questions_add(question) {
      if (question && (!question.is_published && !question.is_premade)) {
        this.updateQuestion(question);
      }
      if (question && question.parent_id) {
        this.updateQuestion(question);
      }
    },
    questions_update(question) {
      if (question && (!question.is_published && !question.is_premade)) {
        this.updateQuestion(question);
      }
      if (question && question.parent_id) {
        this.updateQuestion(question);
      }
    },
    questions_delete(question) {
      if (question && (!question.is_published && !question.is_premade)) {
        this.deleteQuestion(question);
      }
      if (question && question.parent_id) {
        this.deleteQuestion(question);
      }
    },
  },
  data() {
    return {
      isShowAnnouncement: false,
      isShowSettings: false,
      page: 1,
      totalPage: null,
      questions: [],
      isFetching: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    eventSettings() {
      const eventSettings = JSON.parse(this.eventData.settings);
      return eventSettings;
    },
    isQuestionModerated() {
      return this.eventSettings ? this.eventSettings.question_answer.is_moderated : false;
    },
  },
  watch: {
    keyword() {
      this.fetchList(true);
    },
    filterData() {
      this.fetchList(true);
    },
  },
  methods: {
    resetAll() {
      this.questions = [];
    },
    openAnnouncement() {
      this.isShowAnnouncement = true;
    },
    closeAnnouncement() {
      this.isShowAnnouncement = false;
    },
    openSettings() {
      this.isShowSettings = true;
    },
    closeSettings() {
      this.isShowSettings = false;
    },
    loadMore() {
      this.page = this.page + 1;
      this.fetchList(false);
    },
    fetchList(reset = true) {
      if (reset) {
        this.questions = [];
        this.page = 1;
      }
      this.isFetching = true;
      this.$vs.loading();
      let sortBy = 'desc';
      let orderBy = 'created_at';
      if (this.filterData === 'recent' || this.filterData === 'all') sortBy = 'asc';
      if (this.filterData === 'top') {
        sortBy = 'desc';
        orderBy = 'likes';
      }
      const params = {
        page: this.page,
        limit: 25,
        order_by: orderBy,
        sort_by: sortBy,
        event_slug: this.eventData && this.eventData.slug ? this.eventData.slug : null,
        is_published: 0,
        is_premade: 0,
      };
      if (this.keyword) params.keyword = this.keyword;
      if (this.filterData === 'starred') params.is_starred = 1;
      const callback = (response) => {
        const questions = response.data;
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          this.updateQuestion(element);
        }
        this.totalPage = response.last_page;
        this.$vs.loading.close();
        this.isFetching = false;
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
      };
      questionsApi.list(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    updateQuestion(item) {
      // update replies
      if (item && item.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === item.parent_id);
        if (index !== -1) {
          const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === item.id);
          this.$set(this.questions[index].replies, indexReplies, item);
          if (indexReplies === -1) {
            this.questions[index].replies.push(item);
            this.questions[index].meta.total_replies = this.questions[index].meta.total_replies + 1;
          }
        }
      } else {
        const index = this.questions.findIndex(({ id }) => id === item.id);
        this.$set(this.questions, index, item);
        if (index === -1) {
          if (this.filterData === 'all') {
            this.questions.unshift(item);
          } else {
            this.questions.push(item);
          }
        }
      }
    },
    deleteQuestion(item) {
      // remove replies
      if (item && item.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === item.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === item.id);
        this.questions[index].replies.splice(indexReplies, 1);
      } else {
        const index = this.questions.findIndex(({ id }) => id === item.id);
        this.questions.splice(index, 1);
      }
    },
    publishedQuestion(item) {
      const index = this.questions.findIndex(({ id }) => id === item.id);
      this.questions.splice(index, 1);
    },
    publishedAll() {
      this.$vs.loading();
      const callback = () => {
        this.$emit('publishedAll');
        this.fetchList(true);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.publishedAll(this.eventData && this.eventData.id ? this.eventData.id : null, callback, errorCallback);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.fetchList();
    window.addEventListener('resize', this.onResize);
  },
  created() {
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style>
@media (min-width: 992px) {
  .action-moderation{
    position: fixed;
    left: 50.5vw;
  }
}
</style>
