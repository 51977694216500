<template>
	<div v-if="!isFetching" class="relative mt-1">
		<div style="margin-right: 80px; background-color: #F8F8F8; z-index: 9999" class="flex w-full justify-between items-center" :class="{'fixed top-0 mt-20 pt-4  mt-5': windowWidth > 991}">
      <vs-tabs v-model="tabIndex" class="event-details" >
          <vs-tab style="background-color: transparent" label="Live">
          </vs-tab>
          <vs-tab style="background-color: transparent" label="Archive">
          </vs-tab>
          <vs-tab style="background-color: transparent" label="Premade">
          </vs-tab>
      </vs-tabs>
      <div class="pt-1 right-0 mb-2" :class="{'mr-8 mb-0 fixed': windowWidth > 991, 'absolute': windowWidth < 991}">
          <div class="flex items-center">
              <vs-button v-if="windowWidth > 1430 && (tabIndex === 0)" v-tooltip="$t('Archive All Question')" @click="showArchivedAll()" class="btn-icon px-2 p-0 mr-4" color="secondary" type="border">
                  <svg style="height: 28px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>archive</title><path d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" /></svg>
              </vs-button>
              <div class="relative">
                <div class="mr-2 input-search vs-table--search w-3/4 md:w-full"><input type="text" v-model="keyword" class="input-search vs-table--search-input" placeholder="Search ...">
                  <i class="vs-icon notranslate icon-scale material-icons null">search</i>
                </div>
                <i  @click="keyword = ''" class="pointer vs-icon notranslate icon-scale material-icons null absolute right-0 top-0 mr-4" style="margin-top: 12px;">close</i>
              </div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
                      <div v-if="windowWidth > 1430">
                        <svg style="height: 28px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2,3H10A2,2 0 0,1 12,1A2,2 0 0,1 14,3H22V5H21V16H15.25L17,22H15L13.25,16H10.75L9,22H7L8.75,16H3V5H2V3M5,5V14H19V5H5Z" /></svg>
                      </div>
                      <vs-dropdown-menu>
                          <vs-dropdown-item v-if="windowWidth < 1430 && (tabIndex === 0)" @click="showArchivedAll()" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Archive All Question') }}</div>
                              </div>
                          </vs-dropdown-item>
                          <vs-divider v-if="windowWidth < 1430" class="p-0 m-0">Filter</vs-divider>
                          <!-- Recent -->
                          <vs-dropdown-item @click="filterQuestion('recent')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Recent') }}</div>
                                  <svg v-if="filterData === 'all'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- Top -->
                          <vs-dropdown-item @click="filterQuestion('top')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Popular') }}</div>
                                  <svg v-if="filterData === 'top'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- show all -->
                          <vs-dropdown-item @click="filterQuestion('oldest')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Oldest') }}</div>
                                  <svg v-if="filterData === 'oldest'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- starred -->
                          <vs-dropdown-item @click="filterQuestion('starred')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Starred') }}</div>
                                  <svg v-if="filterData === 'starred'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                      </vs-dropdown-menu>
                  </vs-dropdown>
              </div>
              <!-- filter -->
              <div>
                  <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
                      <div v-if="windowWidth > 1430">
                          <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>filter-variant</title><path d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" /></svg>
                      </div>
                      <div v-else>
                        <feather-icon  icon="MoreVerticalIcon" svgClasses="h-8 w-8" />
                      </div>
                      <vs-dropdown-menu>
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="showArchivedAll()" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Archive All Question') }}</div>
                              </div>
                          </vs-dropdown-item>
                          <!-- show all -->
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="filterQuestion('all')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Show All') }} </div>
                                  <svg v-if="filterData === 'all'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <vs-divider v-if="windowWidth < 1430" class="p-0 m-0">Filter</vs-divider>
                          <!-- Recent -->
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="filterQuestion('all')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Recent') }}</div>
                                  <svg v-if="filterData === 'all'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- Top -->
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="filterQuestion('top')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Popular') }}</div>
                                  <svg v-if="filterData === 'top'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- show all -->
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="filterQuestion('oldest')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Oldest') }}</div>
                                  <svg v-if="filterData === 'oldest'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- starred -->
                          <vs-dropdown-item v-if="windowWidth < 1430" @click="filterQuestion('starred')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Starred') }}</div>
                                  <svg v-if="filterData === 'starred'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- show all -->
                          <vs-dropdown-item v-if="windowWidth > 1430" @click="filterQuestion('recent')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Show All') }} </div>
                                  <svg v-if="filterData === 'all'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                          <!-- starred -->
                          <vs-dropdown-item v-if="windowWidth > 1430" @click="filterQuestion('starred')" >
                              <div class="flex items-center question-menu">
                                  <div class="ml-2">{{ $t('Starred') }}</div>
                                  <svg v-if="filterData === 'starred'" class="w-6 ml-2 " style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                              </div>
                          </vs-dropdown-item>
                      </vs-dropdown-menu>
                  </vs-dropdown>
              </div>
          </div>
      </div>
		</div>
    <!-- export item -->
		<vs-popup
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Export')"
			:active.sync="listExport">
			<p class="text-base text-black mb-5">
				{{ $t('Select the file format for the output data') }}
			</p>
			<div class="flex justify-between w-full">
        <vs-button class="w-full m-2 px-2" :class="{ 'is-loading': isExporting }" @click="exportQnA('excel')">
          <div class="flex items-center">
            <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>file-excel</title><path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z" /></svg>
            <div class="ml-2 text-base">{{ $t('Excel File') }}</div>
          </div>
        </vs-button>
        <!-- <vs-button class="w-full m-2 px-2" :class="{ 'is-loading': isExporting }" @click="exportQnA('doc')">
          <div class="flex items-center w-full">
            <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>file-word</title><path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.2,20H13.8L12,13.2L10.2,20H8.8L6.6,11H8.1L9.5,17.8L11.3,11H12.6L14.4,17.8L15.8,11H17.3L15.2,20M13,9V3.5L18.5,9H13Z" /></svg>
            <div class="ml-2 text-base">{{ $t('Text Document') }}</div>
          </div>
        </vs-button> -->
			</div>
		</vs-popup>
    <div style="height: 60px;" v-if="windowWidth > 991"></div>
    <ItemHighlight
      v-if="tabIndex === 0"
      :question="questionHighlight"
      :eventData="eventData"
      @publishedQuestion="publishedQuestion"
      @highlightQuestion="updateHighlight"></ItemHighlight>
    <ItemQuestion
      :questions="questions"
      :eventData="eventData"
      @publishedQuestion="publishedQuestion"
      @resetFilter="resetFilter"
      @highlightQuestion="updateQuestion">
    </ItemQuestion>
    <div class="text-center text-primary font-bold text-xl mt-2">
      <vs-button v-if="!isFetching && totalPage !== page" @click="loadMore()" class="ml-2 btn-icon px-6 py-4">
        {{ $t('Load More') }}
      </vs-button>
    </div>
    <vs-prompt
      class="archived-all"
      :title="$t('Archived All Question')"
      :disabled-outside="true"
      accept-icon="check"
      :buttons-hidden="true"
      :active.sync="isModalConfirmArchive">
      <!-- reply section -->
      <div class="p-4 relative">
          {{ $t('This will move questions to Archive Tab') }}
      </div>
      <div class="button-section">
          <vs-button
              class="px-4 mr-4"
              color="#f3f3f3"
              text-color="black"
              @click="isModalConfirmArchive = false">
              <div class="flex items-center">
                  {{ $t('Cancel') }}
              </div>
          </vs-button>
          <vs-button
              class="px-3 py-3 pr-10"
              @click="archivedAll()">
              <div class="flex items-center">
                  {{ $t('Confirm') }}
                  <svg class="w-6 ml-2 absolute right-0" style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
              </div>
          </vs-button>
        </div>
      </vs-prompt>
    </div>
</template>

<script>
import ItemQuestion from '@/components/qna/ItemQuestion.vue';
import ItemHighlight from '@/components/qna/ItemHighlight.vue';
import { getAxiosErrorMessage, delay } from '@/lib/helper';
import questionsApi from '@/api/question';
import eventsApi from '@/api/event';

export default {
  components: {
    ItemQuestion,
    ItemHighlight,
  },
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabIndex: 0,
      keyword: '',
      questions: [],
      questionHighlight: null,
      isFetching: false,
      filterData: 'all',
      isModalConfirmArchive: false,
      isArchived: false,
      isPremade: false,
      page: 1,
      totalPage: null,
      listExport: false,
      isExporting: false,
      windowWidth: window.innerWidth,
      totalQuestions: 0,
    };
  },
  sockets: {
    questions_add(question) {
      if (question && question.is_published && !question.is_private) {
        this.updateQuestion(question);
      }
      if (question && question.parent_id) {
        this.updateQuestion(question);
      }
      if (this.tabIndex === 2 && question.is_premade) {
        this.updateQuestion(question);
      }
    },
    questions_update(question) {
      if (question && question.is_published && !question.is_private) {
        this.updateQuestion(question);
      }
      if (this.tabIndex === 2 && question.is_premade) {
        this.updateQuestion(question);
      }
    },
    questions_delete(question) {
      if (question && question.is_published && !question.is_private) {
        this.deleteQuestion(question);
      }
      if (this.tabIndex === 2 && question.is_premade) {
        this.deleteQuestion(question);
      }
    },
    questions_highlight(question) {
      if (question && question.is_published && !question.is_private) {
        this.updateQuestion(question);
        this.updateHighlight(question);
        for (let index = 0; index < this.questions.length; index++) {
          const element = this.questions[index];
          if (element.id !== question.id) {
            element.is_highlight = 0;
          }
        }
      }
    },
  },
  computed: {
    eventSettings() {
      const eventSettings = JSON.parse(this.eventData.settings);
      return eventSettings;
    },
  },
  watch: {
    tabIndex() {
      console.log(this.tabIndex);
      if (this.tabIndex === 0) {
        this.isArchived = false;
        this.isPremade = false;
        this.fetchList();
        this.$emit('toggleAddPremade', false);
      } else if (this.tabIndex === 1) {
        this.isArchived = true;
        this.isPremade = false;
        this.fetchList();
        this.$emit('toggleAddPremade', false);
      } else if (this.tabIndex === 2) {
        this.isPremade = true;
        this.isArchived = false;
        this.fetchList();
        this.$emit('toggleAddPremade', true);
      }
    },
    keyword() {
      delay(() => {
        if (this.tabIndex === 0) {
          this.isArchived = false;
          this.isPremade = false;
          this.fetchList();
        } else if (this.tabIndex === 1) {
          this.isArchived = true;
          this.isPremade = false;
          this.fetchList();
        } else if (this.tabIndex === 2) {
          this.isPremade = true;
          this.isArchived = false;
          this.fetchList();
        }
        this.$emit('keywordChange', this.keyword);
      }, 1000);
    },
  },
  methods: {
    resetAll() {
      this.questions = [];
    },
    loadMore() {
      this.page = this.page + 1;
      this.fetchList(false);
    },
    updateEventSettings() {
      const params = {};
      const callback = () => {
        // const message = response.message;
        console.log('masuk bang');
      };
      const errorCallback = (e) => {
        console.log(e);
      };
      const eventSettings = this.eventSettings;
      eventSettings.question_answer.display_sort = this.filterData;
      params.settings = JSON.stringify(eventSettings);
      eventsApi.update(this.eventData.id, params, callback, errorCallback);
    },
    showArchivedAll() {
      this.isModalConfirmArchive = true;
    },
    archivedAll() {
      this.$vs.loading();
      const callback = () => {
        this.fetchList(true);
        this.$vs.loading.close();
        this.isModalConfirmArchive = false;
        this.tabIndex = 1;
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isModalConfirmArchive = false;
      };
      questionsApi.archivedAll(this.eventData && this.eventData.id ? this.eventData.id : null, callback, errorCallback);
    },
    filterQuestion(filter) {
      this.filterData = filter;
      this.updateEventSettings();
      this.fetchList(true);
      this.$emit('filterChange', filter);
    },
    fetchList(reset = true) {
      if (reset) {
        this.questions = [];
        this.page = 1;
      }
      this.isFetching = true;
      this.$vs.loading();
      let sortBy = 'desc';
      let orderBy = 'created_at';
      if (this.filterData === 'recent' || this.filterData === 'all') sortBy = 'desc';
      if (this.filterData === 'top') {
        sortBy = 'desc';
        orderBy = 'likes';
      }
      const params = {
        page: this.page,
        limit: 999,
        order_by: orderBy,
        sort_by: sortBy,
        event_slug: this.eventData && this.eventData.slug ? this.eventData.slug : null,
        is_published: 1,
      };

      params.is_archived = this.isArchived ? 1 : 0;
      params.is_premade = this.isPremade ? 1 : 0;
      params.is_published = this.isPremade ? 0 : 1;
      if (this.filterData === 'starred') params.is_starred = 1;
      if (this.keyword) params.keyword = this.keyword;
      const callback = (response) => {
        const questions = response.data;
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          this.updateQuestion(element);
        }
        this.totalPage = response.last_page;
        this.$vs.loading.close();
        this.isFetching = false;
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
      };
      questionsApi.list(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    fetchHighlight() {
      this.$vs.loading();
      const sortBy = 'desc';
      const orderBy = 'created_at';
      const params = {
        page: this.page,
        limit: 1,
        order_by: orderBy,
        sort_by: sortBy,
        event_slug: this.eventData && this.eventData.slug ? this.eventData.slug : null,
        is_highlight: 1,
        is_archived: 0,
      };
      const callback = (response) => {
        const questions = response.data;
        if (questions && questions.length > 0) {
          this.questionHighlight = questions[0];
        }
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.list(this.eventData && this.eventData.id ? this.eventData.id : null, params, callback, errorCallback);
    },
    updateHighlight(question) {
      if (question && question.is_highlight) {
        this.fetchHighlight();
      } else {
        this.questionHighlight = null;
      }
    },
    updateQuestion(item) {
      // update replies
      if (item && item.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === item.parent_id);
        if (index !== -1) {
          const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === item.id);
          this.$set(this.questions[index].replies, indexReplies, item);
          if (indexReplies === -1) {
            this.questions[index].replies.push(item);
            this.questions[index].meta.total_replies = this.questions[index].meta.total_replies + 1;
          }
        }
      } else {
        const index = this.questions.findIndex(({ id }) => id === item.id);
        this.$set(this.questions, index, item);
        if (item && item.is_highlight) {
          this.questionHighlight = item;
        }
        if (index === -1) {
          if (this.filterData === 'all') {
            this.questions.unshift(item);
          } else {
            this.questions.push(item);
          }
        }
      }
      // delete question when archived
      if (this.tabIndex === 0) {
        if (item && item.is_archived) {
          this.deleteQuestion(item);
        }
      } else if (this.tabIndex === 1) {
        if (item && !item.is_archived) {
          this.deleteQuestion(item);
        }
      } else if (this.tabIndex === 2) {
        if (item && item.is_archived && !item.is_premade) {
          this.deleteQuestion(item);
        }
      }
    },
    deleteQuestion(item) {
      // remove replies
      if (item && item.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === item.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === item.id);
        this.questions[index].replies.splice(indexReplies, 1);
      } else {
        const index = this.questions.findIndex(({ id }) => id === item.id);
        this.questions.splice(index, 1);
      }
    },
    publishedQuestion(item) {
      this.tabIndex = 0;
      const index = this.questions.findIndex(({ id }) => id === item.id);
      this.questions.splice(index, 1);
    },
    resetFilter() {
      this.filterQuestion('all');
    },
    exportList() {
      this.listExport = true;
    },
    exportQnA(type) {
      this.isExporting = true;
      const params = {
        order_by: 'created_at',
        sort_by: 'recent',
        event_id: this.eventData && this.eventData.id ? this.eventData.id : null,
        keyword: this.keyword,
      };
      const callback = async (response) => {
        const url = await fetch(response.data);
        const blob = await url.blob();
        const link = document.createElement('a');
        const fileName = `${this.$t('Q & A')} - ${this.eventData.name}.${type === 'excel' ? 'xlsx' : 'doc'}`;
        const blobUrl = window.URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        this.listExport = false;
        this.isExporting = false;
      };
      const errorCallback = () => {
        this.listExport = false;
        this.isExporting = false;
      };
      questionsApi.exportList(type, params, callback, errorCallback);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.fetchList();
    this.fetchHighlight();
    window.addEventListener('resize', this.onResize);
  },
  created() {
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
    .input-search {
        height: 38px;
        border-radius: none!important;
        .vs-table--search-input {
            border-radius: 4px!important;
        }
    }
</style>
