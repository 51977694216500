<template>
  <div>
    <div v-if="questions && questions.length > 0 && eventIsQna">
      <div v-for="question in questions" :key="question.id" class="item-moderation shadow" @mouseover="onMouseOver(question)" @mouseleave="onMouseLeave()">
        <!-- header -->
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <vs-avatar v-if="question.participant.avatar_thumbnail" size="40px"  :src="question.participant.avatar_thumbnail" />
            <vs-avatar v-else :color="getColor(question && question.sender_name)" :text="getInitial(question && question.sender_name)" size="40px">
              <img src="@/assets/images/file-icons/default-img.jpg" class="responsive pointer" />
            </vs-avatar>
            <div>
              <div class="ml-2">{{ question && question.sender_name }}</div>
              <div class="ml-2 text-secondary text-xs">{{ getDate(question) }}</div>
            </div>
          </div>
          <!-- menu more button -->
          <div class="flex items-center">
            <div v-if="isHover && hoveringItem && hoveringItem.id === question.id" class="flex items-center">
              <!-- favorite -->
              <vs-button v-tooltip="$t('Star Question')" @click="starredQuestion(question)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg v-if="question && !question.is_starred" class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star-outline</title>
                  <path fill="#1863CB" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
                </svg>
                <svg v-else class="w-8" fill="orange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star</title><path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>
              </vs-button>
              <!-- check -->
              <vs-button v-tooltip="$t('Approve Question')" @click="publishedQuestion(question)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="#148b0E" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
              </vs-button>
              <!-- delete -->
              <vs-button v-tooltip="$t('Delete Question')" @click="deleteQuestion(question)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close</title><path fill="red" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
              </vs-button>
            </div>
            <div>
              <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
                <div>
                  <feather-icon  icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
                </div>
                <vs-dropdown-menu>
                  <!-- Edit -->
                  <vs-dropdown-item>
                    <div @click="editQuestion(question)" class="flex items-center moderation-menu">
                      <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg></div>
                      <div class="ml-2">{{ $t('Edit') }}</div>
                    </div>
                  </vs-dropdown-item>
                  <!-- Private Reply -->
                  <vs-dropdown-item>
                    <div @click="privateReply(question)" class="flex items-center moderation-menu">
                      <div><svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>reply</title><path d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z" /></svg></div>
                      <div class="ml-2">{{ $t('Private Reply') }}</div>
                    </div>
                  </vs-dropdown-item>
                  <!-- Delete -->
                  <vs-dropdown-item>
                    <div @click="deleteQuestion(question)" class="flex items-center moderation-menu">
                      <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></div>
                      <div class="ml-2">{{ $t('Delete') }}</div>
                    </div>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
        </div>
        <!-- content -->
        <div class="ml-2 mt-4">
          {{ question && question.name }}
        </div>
        <div @click="privateReply(question)"  v-if="question && question.replies && question.replies.length > 0" class="pointer flex footer-item-question ml-2 mt-8">
            <div class="flex pointer">
              {{ question && question.meta && question.meta.total_replies }} Reply
            </div>
        </div>
      </div>
      <!-- modal private reply -->
      <ModalReplyModeration
        :isShowPrivateReply="isShowPrivateReply"
        @closePrivateReply="closePrivateReply"
        @closeEditQuestion="closeEditQuestion"
        @editQuestion="editQuestion"
        @deleteQuestion="deleteQuestion"
        @updateQuestion="updateQuestion"
        @publishedQuestion="publishedQuestion"
        @starredQuestion="starredQuestion"
        :selectedItem="selectedItem">
      </ModalReplyModeration>
      <ModalEditModeration
        :isShowEdit="isShowEdit"
        @closeEditQuestion="closeEditQuestion"
        @editQuestion="editQuestion"
        @updateQuestion="updateQuestion"
        :eventData="eventData"
        :selectedItem="selectedItem">
      </ModalEditModeration>
    </div>
    <div class="item-moderation shadow" v-if="questions && questions.length === 0 && eventIsQna">
      {{ $t('No questions available') }}
    </div>
    <div class="item-moderation shadow" v-if="!eventIsQna">
      {{ $t('Q & A Feature are disabled, Enabled this feature from Settings menu') }}
    </div>
  </div>
</template>

<script>
import ModalReplyModeration from '@/components/qna/ModalReplyModeration.vue';
import ModalEditModeration from '@/components/qna/ModalEditModeration.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Set the locale to English
import relativeTime from 'dayjs/plugin/relativeTime';
import questionsApi from '@/api/question';

import {
  getAxiosErrorMessage,
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import { mapGetters } from 'vuex';
import noLocale from '@/lib/locale/no';
// Import English locale
dayjs.locale('en');

dayjs.extend(relativeTime);

export default {
  components: {
    ModalReplyModeration,
    ModalEditModeration,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  sockets: {
    questions_delete(question) {
      if (question && question.parent_id) {
        this.onDeleteQuestion(question);
      }
    },
  },
  data() {
    return {
      isHover: false,
      isShowPrivateReply: false,
      isShowEdit: false,
      selectedItem: null,
      hoveringItem: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
    eventIsQna() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let isEnabled = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) isEnabled = settings && settings.question_answer && settings.question_answer.is_enabled ? settings.question_answer.is_enabled : false;
      return isEnabled;
    },
  },
  methods: {
    getDate(question) {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');
      return dayjs(question && question.created_at ? question.created_at : new Date()).fromNow();
    },
    onMouseOver(item) {
      this.isHover = true;
      this.hoveringItem = item;
    },
    onMouseLeave() {
      this.isHover = false;
      this.hoveringItem = null;
    },
    privateReply(question) {
      this.selectedItem = duplicateVar(question);
      this.isShowPrivateReply = true;
    },
    closePrivateReply() {
      this.isShowPrivateReply = false;
    },
    editQuestion(question) {
      this.selectedItem = duplicateVar(question);
      this.isShowEdit = true;
    },
    updateQuestion(question) {
      this.$emit('updateQuestion', question);
    },
    closeEditQuestion() {
      this.isShowEdit = false;
    },
    deleteQuestion(question) {
      this.selectedItem = duplicateVar(question);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete question from')} "${question && question.sender_name ? question.sender_name : ''}"?`,
        accept: () => this.deleteRecord(question),
        acceptText: this.$t('Delete'),
      });
    },
    deleteRecord(question) {
      const callback = () => {
        this.$emit('deleteQuestion', question);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.delete(question && question.id ? question.id : null, callback, errorCallback);
    },
    starredQuestion(question) {
      this.$vs.loading();
      const callback = (response) => {
        const question = response.data;
        this.$emit('updateQuestion', question);
        this.selectedItem = duplicateVar(question);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.starred(question && question.id ? question.id : null, callback, errorCallback);
    },

    publishedQuestion(question) {
      this.$vs.loading();
      const callback = (response) => {
        const question = response.data;
        this.$emit('publishedQuestion', question);
        this.selectedItem = duplicateVar(question);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.published(question && question.id ? question.id : null, callback, errorCallback);
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    onDeleteQuestion(question) {
      const parentId = question && question.parent_id;
      const index = this.questions.findIndex(({ id }) => id === parentId);
      this.questions[index].meta.total_replies -= 1;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
    .item-moderation {
        width: 100%;
        min-height: 180px;
        background-color: white;
        padding: 28px;
		border: 1px solid #F8F8F8;
		&:hover {
			background-color: #F8F8F8;
		}
    }
	.moderation-menu {
		width: 120px;
	}
</style>
