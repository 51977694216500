<template>
  <div v-if="!isFetching" class="xl:mt-12 xl:pt-1 lg:flex lg:gap-8">
    <div style="height: 60px; background-color: #F8F8F8; z-index: 999" class="fixed w-full top-0 left-0"></div>
    <!-- topbar -->
    <div class="flex top-0 right-0 top-bar-qna">
      <div style="z-index: 9999">
        <button class="button-event btn-icon" v-if="isShowAddpremade" @click="isShowModalPremade = true">
            Add Premade Question
          </button>
      </div>
      <vs-dropdown style="z-index: 9999" class="dd-actions cursor-pointer" vs-trigger-click>
        <div>
          <vs-button class="ml-2 btn-icon px-3 py-2 pointer" color="secondary" type="border">
            <div class="flex items-center text-bold">
              {{ $t('Share') }}
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>chevron-down</title>
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          </vs-button>
        </div>
        <vs-dropdown-menu>
          <!-- Share -->
          <vs-dropdown-item @click="shareLinkQuestion()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>link</title>
                  <path
                    d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                </svg></div>
              <div class="ml-2">{{ $t('Question page') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- share -->
          <vs-dropdown-item @click="shareLinkVideo()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>link</title>
                  <path
                    d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                </svg></div>
              <div class="ml-2">{{ $t('Event page URL (with video)') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- Download -->
          <vs-dropdown-item @click="downloadCanvasQR()">
            <div class="flex items-center share-menu">
              <div><svg class="w-6 pt-1 mr-2" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24">
                  <title>qrcode</title>
                  <path
                    d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z" />
                </svg></div>
                <qrcode-vue v-show="false" id="qrcode-png" :value="eventData && eventData.link_code" :size="600" level="H" />
                <qrcode-vue v-show="false" id="qrcode-png-interactive" :value="eventData && eventData.link_code ? `${eventData.link_code}?is_interactive=1` : ''" :size="600" level="H" />
              <div class="">{{ $t('Download QR code') }}</div>
            </div>
          </vs-dropdown-item>
          <vs-dropdown-item @click="exportList()">
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>export</title><path fill="currentColor" d="M23,12L19,8V11H10V13H19V16M1,18V6C1,4.89 1.9,4 3,4H15A2,2 0 0,1 17,6V9H15V6H3V18H15V15H17V18A2,2 0 0,1 15,20H3A2,2 0 0,1 1,18Z" /></svg>

              </div>
              <div class="ml-2">{{ $t('Export') }}</div>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <vs-dropdown style="z-index: 9999" class="dd-actions cursor-pointer" vs-trigger-click>
        <div>
          <vs-button class="ml-2 btn-icon px-3 py-2 pointer" color="secondary" type="border">
            <div class="flex items-center text-bold">
              {{ $t('View') }}
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>chevron-down</title>
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </div>
          </vs-button>
        </div>
        <vs-dropdown-menu>
          <!-- Present new tab -->
          <vs-dropdown-item @click="presentLinkWithParticipant()">
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>open-in-new</title>
                  <path
                    d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                </svg>
              </div>
              <div class="ml-2">{{ $t('Present in a new tab') }}</div>
            </div>
          </vs-dropdown-item>
          <!-- Present full screen -->
          <!-- <vs-dropdown-item >
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>fullscreen</title>
                  <path
                    d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z" />
                </svg>
              </div>
              <div class="ml-2">{{ $t('Present in fullscreen') }}</div>
            </div>
          </vs-dropdown-item> -->
          <!-- Copy present link -->
          <vs-dropdown-item @click="copyPresentLinkToClipboard()">
            <div class="flex items-center view-menu">
              <div>
                <svg class="w-6 pt-1 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>content-copy</title>
                  <path
                    d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                </svg>
              </div>
              <div class="ml-2">{{ $t('Copy Present link') }}</div>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div v-show="!isFetching && eventIsQna" class="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
      <div style="max-height: calc(100vh - 160px); overflow-y: auto;" class="col-6 relative" v-if="!isFetching">
        <ListModeration :eventData="eventData" :keyword="keyword" :filterData="filterData" @publishedAll="publishedAll" ref="moderationList"></ListModeration>
      </div>
      <div style="max-height: calc(100vh - 160px); overflow-y: auto;" class="col-6 relative" v-if="!isFetching">
        <ListQuestion @toggleAddPremade="togglePremade" @keywordChange="keywordChange" @filterChange="filterChange" :eventData="eventData" ref="questionList"></ListQuestion>
      </div>
    </div>
    <div>
      <ModalPremadeQuestion
        :isShowModalPremade="isShowModalPremade"
        @updateQuestion="updateQuestion"
        :eventId="eventData.id">
      </ModalPremadeQuestion>
    </div>
  </div>
</template>

<script>
import ListQuestion from '@/components/qna/ListQuestion.vue';
import ListModeration from '@/components/qna/ListModeration.vue';
import ModalPremadeQuestion from '@/components/qna/ModalPremadeQuestion.vue';
import eventsApi from '@/api/event';
import QrcodeVue from 'qrcode.vue';
import JSZip from 'jszip';
// import questionsApi from '@/api/question';

export default {
  name: 'EventQnA',
  components: {
    ListQuestion,
    ListModeration,
    QrcodeVue,
    ModalPremadeQuestion,
  },
  data() {
    return {
      isFetching: false,
      eventData: null,
      room: null,
      keyword: '',
      filterData: 'all',
      windowWidth: window.innerWidth,
      isShowModalPremade: false,
      selectedItem: {},
      isShowAddpremade: false,
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    questions_reset() {
      this.resetAllQustion();
    },
    events_update(payload) {
      Object.assign(this.eventData, payload);
    },
  },
  methods: {
    togglePremade(status) {
      this.isShowAddpremade = status;
    },
    updateQuestion() {
      this.isShowModalPremade = false;
    },
    presentLinkWithParticipant() {
      const url = `${process.env.VUE_APP_APP_URL}/events/${this.slug}/display`;
      window.open(url, '_blank');
    },
    copyPresentLinkToClipboard() {
      const url = `${process.env.VUE_APP_APP_URL}/events/${this.slug}/display`;
      // Copy the text inside the text field
      navigator.clipboard.writeText(url);
      this.$vs.notify({
        text: 'Present Link copied to clipboard',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
      });
    },
    initSocket() {
      if (this.room) {
        this.$socket.client.emit('join', this.room);
      }
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetchDetails() {
      this.isFetching = true;
      this.$vs.loading();
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        const eventId = this.eventData ? this.eventData.id : '';
        this.room = `event_${eventId}`;
        this.initSocket();
        this.isFetching = false;
        document.title = event.name;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getBySlug(this.slug, callback, errorCallback);
    },
    keywordChange(keyword) {
      this.keyword = keyword;
    },
    filterChange(filter) {
      this.filterData = filter;
    },
    publishedAll() {
      this.$refs.questionList.fetchList(true);
    },
    resetAllQustion() {
      this.$refs.questionList.resetAll();
      this.$refs.moderationList.resetAll();
    },
    shareLinkQuestion() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.linkCodeValue,
        },
      });
      const targetUrl = `${routeData.href}?is_interactive=1`;
      window.open(targetUrl, '_blank');
    },
    shareLinkVideo() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.linkCodeValue,
        },
      });
      const targetUrl = `${routeData.href}`;
      window.open(targetUrl, '_blank');
    },
    downloadCanvasQR() {
      // Get the parent div elements using selectors
      const parentDivInteractive = document.getElementById('qrcode-png-interactive');
      const parentDivStatic = document.getElementById('qrcode-png');

      // Check if the parent div elements are found
      if (!parentDivInteractive || !parentDivStatic) {
        console.error('One or both parent div elements not found');
        return;
      }

      // Find the canvas elements within the parent divs
      const canvasInteractive = parentDivInteractive.querySelector('canvas');
      const canvasStatic = parentDivStatic.querySelector('canvas');

      // Check if the canvas elements are found
      if (!canvasInteractive || !canvasStatic) {
        console.error('One or both canvas elements not found within the parent divs');
        return;
      }

      // Create a JSZip instance
      const zip = new JSZip();

      // Convert the canvases to data URLs representing the PNG images
      const dataUrlInteractive = canvasInteractive.toDataURL('image/png');
      const dataUrlStatic = canvasStatic.toDataURL('image/png');

      // Add the data URLs to the zip file with specified filenames
      zip.file('Event Page.png', dataUrlStatic.split('base64,')[1], { base64: true });
      zip.file('URL for Interactive Only.png', dataUrlInteractive.split('base64,')[1], { base64: true });

      // Generate the zip file
      zip.generateAsync({ type: 'blob' })
        .then((blob) => {
          // Create an anchor element
          const a = document.createElement('a');

          // Set the href attribute to the generated zip file
          a.href = URL.createObjectURL(blob);

          // Set the download attribute with the desired filename
          a.download = 'kobla-qr-codes.zip';

          // Append the anchor element to the document
          document.body.appendChild(a);

          // Trigger a click on the anchor element to start the download
          a.click();

          // Remove the anchor element from the document
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error('Error generating zip file:', error);
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    exportList() {
      this.$refs.questionList.exportList();
    },
  },
  computed: {
    slug() {
      return this.$route.params.eventSlug;
    },
    eventIsQna() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let isEnabled = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) isEnabled = settings && settings.question_answer && settings.question_answer.is_enabled ? settings.question_answer.is_enabled : false;
      return isEnabled;
    },
    linkCodeValue() {
      const linkCode = this.eventData && this.eventData.link_code_value ? this.eventData.link_code_value : '';
      return linkCode;
    },
  },
  created() {
    this.fetchDetails();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
};

</script>
<style>
  .top-bar-qna {
    margin-right: 220px;
    position: absolute;
  }

  @media screen and (max-width: 1200px) {
    .top-bar-qna {
      position: fixed;
      height: 80px;
      z-index: 9999;
    }
  }

  @media screen and (max-width: 575px) {
    .top-bar-qna {
      margin-right: 80px;
    }
  }


  .share-menu {
    min-width: 140px;
  }

  .view-menu {
    min-width: 160px;
  }

  .item-disabled-qna {
    width: 100%;
    min-height: 60px;
    background-color: white;
    padding: 28px;
    border: 1px solid #F8F8F8;
  }

</style>
