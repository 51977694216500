<template>
	<div>
		<vs-prompt
			class="edit-moderation"
            :title="$t('Edit Moderation')"
            @accept="submitData()"
            :is-valid="isFormValid"
            :accept-text="$t('Save')"
            :disabled-outside="true"
            :active.sync="isModalActiveLocal">
            <!-- reply section -->
            <div class="ml-2 relative">
              <div class="flex">
                <vs-textarea
                    class="w-full input-edit-moderation"
                    name="item-content"
                    rows="4"
                    v-model="dataModeration"
                    @input="validateCharCount"
                    id="chatInputQuestion"
                />
                <picker
                  set="emojione"
                  title="Pick your emoji…"
                  :style="{ position: 'absolute', bottom: '50px', right: '20px' }"
                  :showPreview="false"
                  :sheetSize="32"
                  :disabled="isSending"
                  @select="onEmojiSelect"
                  v-if="isShowEmoji"/>
                  <div class="emoji-input pointer mt-2">
                    <vs-icon icon="sentiment_satisfied_alt" @click="showEmoji()"></vs-icon>
                  </div>
                </div>
                <div class="text-right">{{ characterCount }}</div>
            </div>
        </vs-prompt>
    </div>
</template>

<script>
import questionsApi from '@/api/question';
import { Picker } from 'emoji-mart-vue';

import {
  getAxiosErrorMessage,
} from '@/lib/helper';

export default {
  components: {
    Picker,
  },
  props: {
    isShowEdit: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isShowEdit() {
      if (this.isShowEdit) {
        this.isModalActiveLocal = true;
        if (this.selectedItem) {
          this.dataModeration = this.selectedItem.name;
          this.dataIdModeration = this.selectedItem.id;
        }
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeEditQuestion');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataIdModeration: null,
      dataModeration: '',
      dataIsReplyEmail: false,
      isSending: false,
      dataCurrentRole: 'Moderator',
      isShowEmoji: false,
    };
  },
  computed: {
    isFormValid() {
      return this.dataModeration !== '';
    },
    eventInputMaxLength() {
      const eventData = this.eventData && this.eventData.settings ? this.eventData.settings : null;
      let settings = null;
      let settingsInputMaxLength = null;
      if (eventData) settings = JSON.parse(eventData);
      if (settings) settingsInputMaxLength = settings && settings.question_answer && settings.question_answer.input_max_length ? settings.question_answer.input_max_length : false;
      return settingsInputMaxLength;
    },
    exceedsLimit() {
      return this.dataModeration.length > this.eventInputMaxLength; // Change 10 to your desired character count
    },
    characterCount() {
      return `${this.dataModeration.length}/${this.eventInputMaxLength}`;
    },
  },
  methods: {

    showEmoji() {
      this.isShowEmoji = !this.isShowEmoji;
    },
    closeEmoji() {
      this.isShowEmoji = false;
    },
    setInputFocus() {
      // eslint-disable-next-line
			$('#chatInputQuestion').focus();
    },
    onEmojiSelect(emoji) {
      this.dataModeration = `${this.dataModeration} ${emoji.native} `;
      this.closeEmoji();
      this.setInputFocus();
    },
    validateCharCount() {
      if (this.dataModeration.length > this.eventInputMaxLength) { // Change 10 to your desired character count
        this.dataModeration = this.dataModeration.substring(0, this.eventInputMaxLength); // Change 10 to your desired character count
      }
    },
    submitData() {
      this.$vs.loading();
      const params = {
        name: this.dataModeration,
      };
      const callback = (response) => {
        this.$emit('updateQuestion', response.data);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.update(this.dataIdModeration ? this.dataIdModeration : null, params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.moderation-menu {
		width: 120px;
	}
	.moderator-menu {
		width: 160px;
	}
	.edit-moderation.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
        @media (min-width: 500px) {
            min-width: 500px!important;
        }
    }
    .input-edit-moderation {
        border: none!important;
    }
</style>
