<template>
	<div>
		<vs-prompt
			class="private-reply"
      :title="$t('Premade Question')"
      @accept="submitData()"
      :buttons-hidden="true"
      :is-valid="isFormValid"
      :disabled-outside="true"
      :active.sync="isModalActiveLocal">
      <!-- reply section -->
      <div class="ml-2 reply-section relative">
          <vs-textarea
              :label="$t('Type in your Premade question')"
              class="w-full input-private-reply"
              name="item-content"
              rows="4"
              @input="validateCharCount"
              v-model="dataPremadeQuestion"
          />
          <!-- dropdown user on admin side -->
          <div class="relative flex justify-between items-center" v-if="!isFromLive">
              <vs-dropdown class="absolute mt-4 dd-actions cursor-pointer" vs-trigger-click>
                  <div>
                      <div class="flex px-4 items-center">
                          <svg v-if="dataCurrentRole === 'Moderator'" class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                          <svg v-else class="w-6 mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>
                          <div class="ml-2">{{ dataCurrentRole }}</div>
                          <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path fill="gray" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                      </div>
                  </div>
                  <vs-dropdown-menu>
                      <!-- Moderator -->
                      <vs-dropdown-item>
                          <div @click="dataCurrentRole = 'Moderator'" class="flex items-center moderator-menu">
                              <div>
                                  <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                              </div>
                              <div class="ml-2">{{ $t('Moderator') }}</div>
                          </div>
                      </vs-dropdown-item>
                      <!-- Possibly -->
                      <vs-dropdown-item>
                          <div @click="dataCurrentRole = 'Anonymous'" class="flex items-center moderator-menu">
                              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                              <div class="ml-2">{{ $t('Anonymous') }}</div>
                          </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="handleClickCustom">
                          <div  class="flex items-center moderator-menu">
                              <!-- <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div> -->
                              <span class="flex" v-if="!isCustomRole">
                                <div v-if="!dataCustomCurrentRole" class="ml-2">+</div>
                                <div v-if="!dataCustomCurrentRole" style="margin-left: 12px" class="italic">{{ $t('Add new') }}</div>
                                <div class="flex items-center w-full justify-between" v-if="dataCustomCurrentRole">
                                  <div class="flex items-center w-full">
                                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                                    <div class="ml-2 truncate" style="max-width: 120px;">{{ dataCustomCurrentRole }}</div>
                                  </div>
                                  <div @click.stop="isCustomRole = true" class="ml-2 absolute right-0 pr-4  ">
                                    <svg class="w-5 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="grey" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                                  </div>
                                </div>
                              </span>
                              <div v-if="isCustomRole">
                                <div style="" class="italic focus:text-black">
                                  <vs-input
                                    class="w-full pb-2"
                                    name="name"
                                    v-model="dataCustomCurrentRole"
                                    v-validate="'required'"
                                    data-vv-validate-on="blur"
                                    @blur="handleCustomRole"
                                    @keyup.enter="handleCustomRole"
                                  />
                                </div>
                              </div>
                          </div>
                      </vs-dropdown-item>
                  </vs-dropdown-menu>
              </vs-dropdown>
              <!-- send button -->
              <div class="absolute right-0 mt-5 mr-1 px-3 py-2">
                  <div class="flex items-center">
                      <div class="mr-4">{{ characterCount }}</div>
                      <vs-button
                          :class="{ 'is-loading': isSending }"
                          class="px-3 py-2"
                          :disabled="!isFormValid"
                          @click="submitData">
                          <div class="flex items-center">
                              {{ $t('Send') }}
                              <svg class="w-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                          </div>
                      </vs-button>
                  </div>
              </div>
          </div>
          <!-- dropdown user on live side -->
          <div class="relative flex justify-between items-center" v-if="isFromLive">
              <div div class="absolute left-0 mt-5 mr-1 px-3 py-2">
                <user-selector
                  :is-show-voting-as-label="false"
                  :event="eventData"
                  @setSelectedUser="setSelectedUser"
                  >
                </user-selector>
              </div>
              <!-- send button -->
              <div class="absolute right-0 mt-5 mr-1 px-3 py-2">
                  <div class="flex items-center">
                      <div class="mr-4">{{ characterCount }}</div>
                      <vs-button
                          :class="{ 'is-loading': isSending }"
                          class="px-3 py-2"
                          :disabled="!isFormValid"
                          @click="replyFromLive">
                          <div class="flex items-center">
                              {{ $t('Send') }}
                              <svg class="w-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                          </div>
                      </vs-button>
                  </div>
              </div>
          </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Set the locale to English
import relativeTime from 'dayjs/plugin/relativeTime';
import questionsApi from '@/api/question';
import UserSelector from '@/components/sender/UserSelector.vue';


import {
  // duplicateVar,
  getAxiosErrorMessage,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import { mapGetters } from 'vuex';
import noLocale from '@/lib/locale/no';
// Import English locale
dayjs.locale('en');

dayjs.extend(relativeTime);
export default {
  components: {
    UserSelector,
  },
  props: {
    isShowModalPremade: {
      type: Boolean,
      default: () => false,
    },
    eventId: {
      type: Number,
      default: () => null,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    isFromLive: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Object,
      default: () => null,
    },
    parentQuestion: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    isShowModalPremade() {
      if (this.isShowModalPremade) {
        this.isModalActiveLocal = true;
        this.dataPremadeQuestion = '';
        this.dataIsReplyEmail = false;
        this.isSending = false;
        this.dataCurrentRole = 'Moderator';
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('updateQuestion');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataPremadeQuestion: '',
      dataIsReplyEmail: false,
      isSending: false,
      dataCurrentRole: 'Moderator',
      isCustomRole: false,
      dataCustomCurrentRole: '',

      // from live
      selectedUser: null,
    };
  },
  computed: {
    qustionAnswerInputLength() {
      let inputLength = 160;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings) inputLength = qnaSettings.input_max_length;
      return inputLength;
    },
    isFormValid() {
      return this.dataPremadeQuestion !== '';
    },
    exceedsLimit() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      return this.dataPremadeQuestion.length > maxLength; // Change 10 to your desired character count
    },
    characterCount() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      return `${this.dataPremadeQuestion.length}/${maxLength}`;
    },
    ...mapGetters({
      user: 'user',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
  },
  methods: {
    handleClickCustom(event) {
      if (!this.dataCustomCurrentRole || this.isCustomRole) {
        event.stopPropagation();
      }
      this.addNewRole();
    },
    addNewRole() {
      if (!this.dataCustomCurrentRole) {
        this.isCustomRole = true;
      } else {
        this.dataCurrentRole = this.dataCustomCurrentRole;
      }
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    handleCustomRole() {
      this.dataCurrentRole = this.dataCustomCurrentRole;
      this.isCustomRole = false;
      if (!this.dataCustomCurrentRole) this.dataCurrentRole = 'Moderator';
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    validateCharCount() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      if (this.dataPremadeQuestion.length > maxLength) { // Change 10 to your desired character count
        this.dataPremadeQuestion = this.dataPremadeQuestion.substring(0, maxLength); // Change 10 to your desired character count
      }
    },
    getDate(question) {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');
      return dayjs(question && question.created_at ? question.created_at : new Date()).fromNow();
    },
    submitData() {
      this.$vs.loading();
      const params = {
        name: this.dataPremadeQuestion,
        sender_name: this.dataCurrentRole,
        parent_id: null,
        is_private: false,
        is_reply_to_email: false,
        is_premade: true,
      };
      const callback = (response) => {
        this.$emit('updateQuestion', response.data);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.create(this.eventId, params, callback, errorCallback);
    },

    // from live
    setSelectedUser(user) {
      this.selectedUser = user;
    },

    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    const CustomCurrentRole = localStorage.getItem('CustomCurrentRole');
    this.dataCustomCurrentRole = CustomCurrentRole;
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.moderation-menu {
		width: 120px;
	}
	.moderator-menu {
		width: 160px;
	}
	.private-reply.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
        @media (min-width: 800px) {
            min-width: 800px!important;
        }
    }
    .reply-section {
        margin-top: 0px;
        width: 98%;
        border: 1px solid rgb(190, 190, 190);
        min-height: 210px;
        border-radius: 6px;
    }
    .input-private-reply {
        border: none!important;
        border-bottom: 1px solid rgb(190, 190, 190)!important;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        padding-bottom:30px;
    }
</style>
