<template>
  <div v-if="question">
    <div class="item-question shadow" @mouseover="onMouseOver(question)" @mouseleave="onMouseLeave()" :class="{'highlight-question text-white': question.is_highlight}" >
      <!-- header -->
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <vs-avatar v-if="question.participant.avatar_thumbnail && !isHideSenderName" size="40px" class="text-3xl mt-4" :src="question.participant.avatar_thumbnail"/>
          <vs-avatar  v-else :color="getColor(question && question.sender_name)" :text="getInitial(question && question.sender_name)" size="40px"
            :style="getColor(question && question.sender_name) === '#0154C7' ? 'border: 1px solid white' : ''">
            <img src="@/assets/images/file-icons/default-img.jpg" class="responsive pointer" />
          </vs-avatar>
          <div>
            <div class="ml-2">{{ question && question.sender_name }}</div>
            <div class="ml-2 text-secondary text-xs">{{ getDate(question) }}</div>
          </div>
        </div>
        <!-- menu more button -->
        <div class="flex items-center">
          <div v-if="isHover && hoveringItem && hoveringItem.id === question.id" class="flex items-center">
            <!-- favorite -->
            <vs-button @click="starredQuestion(question)" class="ml-2 btn-icon px-2 py-1" :color="question && question.is_highlight ? 'white' : 'secondary'" :type="question && question.is_highlight === 0 ? 'border' : 'filled'">
              <svg v-if="question && !question.is_starred" class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star-outline</title>
                <path fill="#1863CB" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
              </svg>
              <svg v-else class="w-8" fill="orange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star</title><path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>
            </vs-button>
            <!-- highlight -->
            <vs-button @click="highlightQuestion(question)" class="ml-2 btn-icon px-2 py-1" :color="question && question.is_highlight ? 'white' : 'secondary'" :type="question && question.is_highlight === 0 ? 'border' : 'filled'">
              <svg v-if="question && !question.is_highlight" class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-double-up</title><path  d="M7.41,18.41L6,17L12,11L18,17L16.59,18.41L12,13.83L7.41,18.41M7.41,12.41L6,11L12,5L18,11L16.59,12.41L12,7.83L7.41,12.41Z" /></svg>
              <svg v-else class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path fill="#0154C7" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
            </vs-button>
            <!-- check -->
            <vs-button @click="archivedQuestion(question)" class="ml-2 btn-icon px-2 py-1" :color="question && question.is_highlight ? 'white' : 'secondary'" :type="question && question.is_highlight === 0 ? 'border' : 'filled'">
              <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle-outline</title><path fill="#148b0E" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>
            </vs-button>
          </div>
          <div>
            <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
              <div>
                <feather-icon  icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
              </div>
              <vs-dropdown-menu>
                <!-- Edit -->
                <vs-dropdown-item>
                  <div @click="editQuestion(question)" class="flex items-center question-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg></div>
                    <div class="ml-2">{{ $t('Edit') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Reply -->
                <vs-dropdown-item>
                  <div @click="replies(question)" class="flex items-center question-menu">
                    <div><svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>reply</title><path d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z" /></svg></div>
                    <div class="ml-2">{{ $t('Reply') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- archive -->
                <vs-dropdown-item @click="archivedQuestion(question)">
                  <div class="flex items-center question-menu">
                    <div><svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>archive</title><path d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" /></svg></div>
                    <div class="ml-2">{{ question && question.is_archived ? $t('Unarchive') : $t('Archive') }}</div>
                  </div>
                </vs-dropdown-item>
                <!-- Delete -->
                <vs-dropdown-item>
                  <div @click="deleteQuestion(question)" class="flex items-center question-menu">
                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></div>
                    <div class="ml-2">{{ $t('Delete') }}</div>
                  </div>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>
      <!-- content -->
      <div class="ml-2 mt-4">
        {{ question && question.name }}
      </div>
          <!-- footer -->
          <div v-if="question && !question.is_highlight" class="flex footer-item-question ml-2 mt-8">
              <div class="flex items-center mr-6">
                  <svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>thumb-up</title><path fill="#0154c7" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" /></svg>
                  <div class="ml-2">
                      {{ question && question.likes ? question.likes : 0 }}
                  </div>
              </div>
              <div @click="replies(question)" class="flex pointer">
                  {{ question && question.meta && question.meta.total_replies }} Reply
              </div>
          </div>
          <div v-else class="flex text-white ml-2 mt-8">
              <div class="flex items-center mr-6">
                  <svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>thumb-up</title><path fill="white" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" /></svg>
                  <div class="ml-2">
                      {{ question && question.likes ? question.likes : 0 }}
                  </div>
              </div>
              <div @click="replies(question)" class="flex pointer">
                  {{ question && question.meta && question.meta.total_replies }} Reply
              </div>
          </div>
    </div>
    <!-- modal replies -->
    <ModalReplyQuestion
      :isShowReplies="isShowReplies"
      @closeReplies="closeReplies"
      @closeEditQuestion="closeEditQuestion"
      @editQuestion="editQuestion"
      @deleteQuestion="deleteQuestion"
      @updateQuestion="updateQuestion"
      @publishedQuestion="publishedQuestion"
      @highlightQuestion="highlightQuestion"
      @starredQuestion="starredQuestion"
      :selectedItem="selectedItem"
    ></ModalReplyQuestion>
    <ModalEditQuestion
    :isShowEdit="isShowEdit"
    :eventData="eventData"
    :selectedItem="selectedItem"
    @closeEditQuestion="closeEditQuestion"
    @updateQuestion="updateQuestion"
    @deleteQuestion="deleteQuestion"
    @editQuestion="editQuestion">
    </ModalEditQuestion>
  </div>
</template>

<script>
import ModalReplyQuestion from '@/components/qna/ModalReplyQuestion.vue';
import ModalEditQuestion from '@/components/qna/ModalEditQuestion.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Set the locale to English
import relativeTime from 'dayjs/plugin/relativeTime';
import questionsApi from '@/api/question';

import {
  getAxiosErrorMessage,
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import { mapGetters } from 'vuex';
import noLocale from '@/lib/locale/no';
// Import English locale
dayjs.locale('en');

dayjs.extend(relativeTime);

export default {
  components: {
    ModalReplyQuestion,
    ModalEditQuestion,
  },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isHover: false,
      isHighlight: false,
      isNotFavorite: false,
      isShowReplies: false,
      isShowEdit: false,
      selectedItem: null,
      hoveringItem: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
  },
  methods: {
    getDate(question) {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');
      return dayjs(question && question.created_at ? question.created_at : new Date()).fromNow();
    },
    onMouseOver(item) {
      this.hoveringItem = item;
      this.isHover = true;
    },
    onMouseLeave() {
      this.hoveringItem = null;
      this.isHover = false;
    },
    replies(question) {
      this.selectedItem = duplicateVar(question);
      this.isShowReplies = true;
    },
    closeReplies() {
      this.isShowReplies = false;
    },
    editQuestion(question) {
      this.selectedItem = duplicateVar(question);
      this.isShowEdit = true;
    },
    updateQuestion(question) {
      this.$emit('updateQuestion', question);
    },
    closeEditQuestion() {
      this.isShowEdit = false;
    },
    deleteQuestion(question) {
      this.selectedItem = duplicateVar(question);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete question from')} "${question && question.sender_name ? question.sender_name : ''}"?`,
        accept: () => this.deleteRecord(question),
        acceptText: this.$t('Delete'),
      });
    },
    deleteRecord(question) {
      const callback = () => {
        this.$emit('deleteQuestion', question);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.delete(question && question.id ? question.id : null, callback, errorCallback);
    },

    starredQuestion(question) {
      this.$vs.loading();
      const callback = (response) => {
        const question = response.data;
        this.$emit('updateQuestion', question);
        this.selectedItem = duplicateVar(question);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.starred(question && question.id ? question.id : null, callback, errorCallback);
    },

    publishedQuestion(question) {
      this.$vs.loading();
      const callback = (response) => {
        const question = response.data;
        this.$emit('publishedQuestion', question);
        this.selectedItem = duplicateVar(question);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.published(question && question.id ? question.id : null, callback, errorCallback);
    },

    highlightQuestion(question) {
      this.$vs.loading();
      const callback = (response) => {
        this.$emit('highlightQuestion', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.highlight(question && question.id ? question.id : null, callback, errorCallback);
    },

    async archivedQuestion(question) {
      if (question.is_highlight) await this.highlightQuestion(question);
      this.$vs.loading();
      const callback = (response) => {
        this.$emit('archivedQuestion', response.data);
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.archived(question && question.id ? question.id : null, callback, errorCallback);
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
.item-question {
  width: 100%;
  min-height: 180px;
  background-color: white;
  padding: 28px;
  border: 1px solid #F8F8F8;
  &:hover {
    background-color: #F8F8F8;
  }
}
.highlight-question {
  background-color: #0154C7;
  &:hover {
    background-color: #0154C7;
  }
}
.question-menu {
  width: 142px;
}
.footer-item-question {
      color: #0154c7;
}
</style>
