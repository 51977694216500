<template>
	<div>
		<vs-prompt
			class="announcement"
            :title="`Announcement ${isActive ? '- Active' : ''}`"
            @accept="submitData()"
            :buttons-hidden="true"
            :is-valid="isFormValid"
            :disabled-outside="true"
            :active.sync="isModalActiveLocal">
            <!-- reply section -->
            <div class="ml-2 announce-section relative">
                <vs-textarea
                    :label="$t('Add information to announce to your participants')"
                    class="w-full input-announce"
                    name="item-content"
                    rows="4"
                    @input="validateCharCount"
                    v-model="dataTitle"
                    :disabled="dataIsAnnouncementActive === 1"
                />
                <div class="relative flex justify-between items-center">
                    <!-- send button -->
                    <div class="absolute right-0 mr-1 px-3 mt-5">
                        <div class="flex items-center">
                            <div class="mr-4">{{ characterCount }}</div>
                            <vs-button
                                v-if="!dataIsAnnouncementActive"
                                :class="{ 'is-loading': isSending }"
                                class="px-3 py-2"
                                :disabled="!isFormValid"
                                @click="submitData">
                                <div class="flex items-center">
                                    {{ $t('Send') }}
                                    <svg class="w-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                                </div>
                            </vs-button>
                            <vs-button
                                v-if="dataIsAnnouncementActive"
                                :class="{ 'is-loading': isSending }"
                                color="danger"
                                class="px-3 py-2"
                                :disabled="!dataIsAnnouncementActive"
                                @click="updateStatus(dataId)">
                                <div class="flex items-center">
                                    <svg style="width: 18px" class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>checkbox-blank</title><path fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" /></svg>
                                    {{ $t('Stop Announcement') }}
                                </div>
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vs-prompt>
    </div>
</template>

<script>
import announcementApi from '@/api/announcement';
import { getAxiosErrorMessage } from '../../lib/helper';

export default {
  components: {
  },
  props: {
    isShowAnnouncement: {
      type: Boolean,
      default: () => false,
    },
    eventId: {
      type: Number,
      default: () => null,
    },
  },
  watch: {
    isShowAnnouncement() {
      if (this.isShowAnnouncement) {
        this.isModalActiveLocal = true;
        this.fetchAnnouncement();
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeAnnouncement');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      isSending: false,
      dataCurrentRole: 'Moderator',
      dataTitle: '',
      dataDescription: '',
      dataId: null,
      dataIsAnnouncementActive: false,
      isActive: false,
    };
  },
  computed: {
    isFormValid() {
      return this.dataTitle !== '';
    },
    params() {
      const params = {
        id: this.dataId,
        title: this.dataTitle,
        description: this.dataDescription,
        event_id: this.eventId,
        is_active: this.dataIsAnnouncementActive,
      };
      return params;
    },
    exceedsLimit() {
      return this.dataTitle.length > 300; // Change 10 to your desired character count
    },
    characterCount() {
      return `${this.dataTitle.length}/${300}`;
    },
  },
  methods: {
    validateCharCount() {
      if (this.dataTitle.length > 300) { // Change 10 to your desired character count
        this.dataTitle = this.dataTitle.substring(0, 300); // Change 10 to your desired character count
      }
    },
    fetchAnnouncement() {
      const notifTitle = this.$t('Announcement');
      const params = {
        limit: 1,
        page: 1,
        event_id: this.eventId,
      };
      const callback = (response) => {
        this.isSending = false;
        const currentAnnouncement = response && response.data && response.data[0] ? response.data[0] : null;
        if (currentAnnouncement) {
          this.dataTitle = currentAnnouncement.title;
          this.dataDescription = currentAnnouncement.description;
          this.dataId = currentAnnouncement.id;
          this.dataIsAnnouncementActive = currentAnnouncement.is_active;
        }
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSending = false;
      };
      announcementApi.getList(params, callback, errorCallback);
    },
    submitData() {
      this.isSending = true;
      const notifTitle = this.$t('Announcement');
      const isUpdate = !!this.dataId;
      const params = this.params;
      const callback = (response) => {
        // const message = response.message;
        this.isSending = false;
        if (isUpdate || !this.dataIsAnnouncementActive) {
          this.updateStatus(response.data.id);
        }
        this.isModalActiveLocal = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isModalActiveLocal = false;
        this.isSending = false;
      };

      if (isUpdate) {
        announcementApi.update(this.dataId, params, callback, errorCallback);
      } else {
        announcementApi.create(params, callback, errorCallback);
      }
    },
    updateStatus(id) {
      this.isSending = true;
      const notifTitle = this.$t('Announcement');
      const callback = (response) => {
        // const item = response.data;
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isModalActiveLocal = false;
        this.isSending = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isModalActiveLocal = false;
        this.isSending = false;
      };
      announcementApi.updateStatus(id, callback, errorCallback);
    },
    deleteData() {
      this.isModalActiveLocal = false;
      this.$emit('deleteData');
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.announcement.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
		@media (min-width: 800px) {
            min-width: 800px!important;
        }
    }
    .announce-section {
        margin-top: 0px;
        width: 98%;
        border: 1px solid rgb(190, 190, 190);
        min-height: 210px;
        border-radius: 6px;
    }
    .input-announce {
        border: none!important;
        border-bottom: 1px solid rgb(190, 190, 190)!important;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        padding-bottom:30px;
    }
</style>
